function userIsLogged() {
  if (localStorage.getItem(process.env.VUE_APP_SESSION_KEY)) {
    let user = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_SESSION_KEY)
    );
    if (user && user.access_token) {
      return true;
    } else return false;
  } else return false;
}

function saveUserSession(session_data) {
  localStorage.setItem(
    process.env.VUE_APP_SESSION_KEY,
    JSON.stringify(session_data)
  );
}

function getUserData() {
  if (userIsLogged())
    return JSON.parse(localStorage.getItem(process.env.VUE_APP_SESSION_KEY))
      .user;
  else return null;
}

export default { userIsLogged, saveUserSession, getUserData };
