<template>
  <!--Message
    v-if="SystemNotification"
    :severity="SystemNotification.severity"
    :sticky="true"
    @close="$store.dispatch('changeSystemNotification', null)"
    class="system-nitification"
    >{{ SystemNotification.content }}</Message
  -->
  <!-- Toast / -->
  <Error v-if="$route.path === '/error'" />
  <NotFound v-else-if="$route.path === '/notfound'" />
  <Access v-else-if="$route.path === '/access'" />
  <App v-else @change-theme="changeTheme" />
</template>

<script>
import App from "./App";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";

export default {
  methods: {
    changeTheme(event) {
      let themeElement = document.getElementById("theme-link");
      themeElement.setAttribute(
        "href",
        themeElement
          .getAttribute("href")
          .replace(this.$appState.theme, event.theme)
      );
      this.$appState.theme = event.theme;
      this.$appState.darkTheme = event.dark;

      if (event.theme.startsWith("md")) {
        this.$primevue.config.ripple = true;
      }
    },
  },
  watch: {
    "$store.getters.getSystem_message": {
      deep: false,
      handler(newVal) {
        if (newVal) this.$toast.add(newVal);
      },
    },
  },
  computed: {
    SystemNotification() {
      return this.$store.getters.getSystem_notification;
    },
  },
  components: {
    App,
    Error,
    NotFound,
    Access,
  },
};
</script>
