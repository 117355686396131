import { createStore } from "vuex";

// import auth from "./modules/auth";

export const store = createStore({
  state: {
    system_message: null,
    system_notification: null,
  },
  mutations: {
    setSystem_message: (state, data) => {
      state.system_message = data;
    },
    setSystem_notification: (state, data) => {
      state.system_notification = data;
    },
  },
  actions: {
    changeSystemMessage({ commit }, data) {
      commit("setSystem_message", data);
    },
    changeSystemNotification({ commit }, data) {
      commit("setSystem_notification", data);
    },
  },
  getters: {
    getSystem_message: (state) => {
      return state.system_message;
    },
    getSystem_notification: (state) => {
      return state.system_notification;
    },
  },
  modules: {
    // auth,
  },
});
