<template>
    <div class="error-background surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-4 lg:p-0" style="min-width:80%;">
            <div class="col-12 mt-5 xl:mt-5 text-center">
                <img src="layout/images/logo-error.svg" alt="Sakai logo" class="mb-5" style="width:100%; height:50px;">
            </div>
            <div class="col-12 xl:col-6" style="border: 1px solid rgba(0, 0, 0, 0.25); border-radius: 18px; padding: 1rem;">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius: 12px;border: 1px solid rgb(0 0 0 / 10%);background: linear-gradient(150.64deg, rgba(255, 255, 255, 0.5) 16.98%, rgba(255, 255, 255, 0) 39.9%, rgba(255, 255, 255, 0.5) 63.29%, rgba(255, 255, 255, 0) 85.73%);/* background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0)); */">
                    <div class="grid flex flex-column align-items-center">
                        <div class="#">
                            <i class="#"></i>
                        </div>
                        <h1 class="font-bold text-5xl text-900 mb-2">Ocurrió un Error</h1>
                        <span class="text-600 font-size: 1rem;">El sitio que ha solicitado no está disponible.</span>
                        <img src="layout/images/asset-error.svg" alt="Error" class="mt-5" width="80%">
                        <div class="col-5 mt-6 text-center error-button">
                            <i class="pi pi-fw pi-arrow-left text-blue-50 mr-2" style="vertical-align:center;"></i>
                            <router-link to="/" class="text-blue-50">Volver a Inicio Sesión</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>